<template>
    <div>
        <Preloader v-if='loader' />

        <h1 class='mb-4 page-title'>Редактирование платежа</h1>

        <div class='row justify-between'>
            <div class='col-4'>
                <div>Создан:</div>
                <div>{{ form.creator ? form.creator : 'Автоматически' }}</div>
            </div>

            <div class='col-4'>
                <div>Создано:</div>
                <div>{{ form.created_at }}</div>
            </div>

            <div class='col-4'>
                <div>Обновлено:</div>
                <div>{{ form.updated_at }}</div>
            </div>
        </div>

        <div>
            <b-form class='g-1 p-4' @submit.prevent='submitForm' @reset.prevent='resetForm'>
                <div class='widget row'>
                    <b-form-group label='Transaction uuid: *' class='col-6' label-for='input-transaction-uuid'>
                        <b-form-input
                            id='input-transaction-uuid'
                            type='text'
                            placeholder='Transaction uuid'
                            :readonly='!this.form.edit'
                            :value='form.transaction_uuid'
                            disabled
                        />
                    </b-form-group>

                    <b-form-group label='Provider: *' class='col-6' label-for='input-provider'>
                        <b-form-input
                            id='input-provider'
                            type='text'
                            placeholder='Provider'
                            :readonly='!this.form.edit'
                            :value='form.provider'
                            disabled
                        />
                    </b-form-group>

                    <b-form-group label='Цена: *' class='col-6' label-for='input-1'>
                        <b-form-input
                            id='input-1'
                            type='number'
                            placeholder='Цена'
                            :readonly='!this.form.edit'
                            v-model.number='form.amount'
                        />
                    </b-form-group>

                    <b-form-group label='Начальная цена: *' class='col-6' label-for='input-2'>
                        <b-form-input
                            id='input-2'
                            type='number'
                            placeholder='Начальная цена'
                            :readonly='!this.form.edit'
                            v-model.number='form.origin_amount'
                            required
                        />
                    </b-form-group>

                    <b-form-group label='Валюта: *' class='col-6' label-for='input-3'>
                        <b-form-select
                            v-if='currency_list'
                            id='input-3'
                            v-model='form.currency'
                        >
                            <b-form-select-option
                                v-for='(v, k) in currency_list'
                                :key='k'
                                :value='k'
                                :selected='k === form.currency'
                                :disabled='!form.edit'
                            >
                                {{ v }}
                            </b-form-select-option>
                        </b-form-select>
                    </b-form-group>

                    <b-form-group label='Статус: *' class='col-6' label-for='input-4'>
                        <b-form-select
                            v-if='statuses'
                            id='input-4'
                            v-model='form.status'
                            required
                        >
                            <b-form-select-option
                                v-for='(v, k) in statuses'
                                :key='k'
                                :value='k'
                                :selected='k === form.status'
                            >
                                {{ v }}
                            </b-form-select-option>
                        </b-form-select>
                    </b-form-group>

                    <div class='col-12 mb-2'>Студент:</div>
                    <div v-if='students' class='radio-select-wrap col-6'>
<!--                        <b-form-radio v-model='studentType' name='radio' value='student_id' />-->
                        <Multiselect
                            class='custom__multiselect'
                            selectLabel='нажмите чтобы выбрать'
                            deselectLabel='нажмите чтобы убрать'
                            tagPosition='bottom'
                            label='name'
                            track-by='id'
                            placeholder='Выберите студента'
                            :value="student"
                            @select="handleStudentSelect"
                            :show-no-results='true'
                            :searchable='true'
                            :loading='studentsMultiselectLoading'
                            :internal-search='false'
                            :options='students.data'
                            @search-change='searchStudent'
                        >
                            <template slot='afterList'>
                                <MultiselectAfterList
                                    :current-page='students.meta.current_page'
                                    :last-page='students.meta.last_page'
                                    :callback='fetchStudents'
                                />
                            </template>

                            <template slot="singleLabel" slot-scope="props">
                                <span class="option__desc">
                                    <span class="option__title">{{ props.option.name }} - {{ props.option.parent_phone }}</span>
                                </span>
                            </template>

                            <template slot="option" slot-scope="props">
                                <div class="option__desc">
                                    <span class="option__title">{{ props.option.name }} - {{ props.option.parent_phone }}</span>
                                </div>
                            </template>

                            <span slot='noResult'>Нет студентов с таким именем</span>
                            <span slot='noOptions'>Список студентов загружается...</span>
                        </Multiselect>
                    </div>

                    <div class='radio-select-wrap col-6'>
<!--                        <b-form-radio v-model='studentType' name='radio' value='info' />-->
<!--                        :disabled="!selectedStudentType('info') || !form.edit"-->
                        <b-form-input class='mb-2' v-model='form.name' type='text' placeholder='Имя' />
                        <b-form-input v-model='form.phone' type='text' placeholder='Телефон' />
                    </div>

                    <div class='col-12 mb-2'>Продукт:</div>
                    <div v-if='products' class='radio-select-wrap col-6'>
                        <b-form-radio v-model='productType' name='productType' value='subscription' />

                        <Multiselect
                            id='input-6'
                            class='custom__multiselect'
                            selectLabel='нажмите чтобы выбрать'
                            deselectLabel='нажмите чтобы убрать'
                            tagPosition='bottom'
                            :tagable='true'
                            label='title'
                            track-by='id'
                            placeholder='Выберите Абонемент'
                            :show-no-results='true'
                            :searchable='true'
                            :loading='productsMultiselectLoading'
                            :internal-search='false'
                            :disabled="!selectedProductType('subscription') || !form.edit"
                            :options='products.data'
                            v-model='product'
                            @search-change='searchProduct'
                        >
                            <template slot='afterList'>
                                <MultiselectAfterList
                                    :current-page='products.meta.current_page'
                                    :last-page='products.meta.last_page'
                                    :callback='fetchProducts'
                                />
                            </template>

                            <span slot='noResult'>Нет абонементов с таким именем</span>
                            <span slot='noOptions'>Список абонементов загружается...</span>
                        </Multiselect>
                    </div>

                    <div class='radio-select-wrap col-6'>
                        <b-form-radio v-model='productType' name='productType' value='info' />
                        <b-form-input
                            v-model='form.product_name'
                            type='text'
                            placeholder='Название продукта'
                            :disabled="!selectedProductType('info') || !form.edit"
                        />
                    </div>

                    <div class='col-12 mb-2'>Промокод:</div>
                    <div v-if='promoCodes' class='radio-select-wrap col-6'>
                        <Multiselect
                            class='custom__multiselect'
                            selectLabel='нажмите чтобы выбрать'
                            deselectLabel='нажмите чтобы убрать'
                            tagPosition='bottom'
                            label='name'
                            track-by='id'
                            placeholder='Выберите промокод'
                            :show-no-results='true'
                            :searchable='true'
                            :loading='promoCodesMultiselectLoading'
                            :internal-search='false'
                            :custom-label='promoCodeCustomLabel'
                            :options='promoCodes.data'
                            v-model='promoCode'
                            @search-change='searchPromoCodes'
                            :disabled='!form.edit'
                        >
                            <template slot='afterList'>
                                <MultiselectAfterList
                                    :current-page='promoCodes.meta.current_page'
                                    :last-page='promoCodes.meta.last_page'
                                    :callback='fetchPromoCodes'
                                />
                            </template>

                            <span slot='noResult'>Нет промокода с таким именем</span>
                            <span slot='noOptions'>Список промокодов загружается...</span>
                        </Multiselect>
                    </div>

                    <b-form-group label='Комментарий:' class='col-12' label-for='input-10'>
                        <b-form-textarea id='input-10' v-model='form.comment' type='text' placeholder='Комментарий' />
                    </b-form-group>
                </div>

                <b-form-group class='row justify-content-end'>
                    <b-button type='submit' class='mr-2' variant='success'>Сохранить</b-button>
                    <b-button type='reset' variant='primary'>Назад</b-button>
                </b-form-group>
            </b-form>

            <div class='g-1 p-4'>
                <div class='d-flex items-center justify-between mb-4'>
                    <h2 class='page-title m-0 flex-grow-1'>Возвраты</h2>
                    <b-button
                        v-if='isAdmin'
                        type='button'
                        class='ml-2'
                        variant='primary'
                        @click='openRefundModal(form)'
                        :disabled="!this.form.refund_enable"
                    >
                        Добавить возврат
                    </b-button>
                </div>

                <div v-if='refundItems' class='widget row'>
                    <b-table striped hover :items='refundItems' :fields='refundFields' />
                </div>
            </div>

            <div class='g-1 p-4' v-if="student">
                <div class='d-flex items-center justify-between mb-4'>
                    <h2 class='page-title m-0 flex-grow-1'>История платежей студента</h2>
                </div>

                <transactions-table-component
                    v-if="studentHistory && Object.keys(studentHistory).length"
                    :table-data="studentHistory"
                />
            </div>
        </div>
    </div>
</template>

<script>
import { debounce } from 'debounce';
import { mapGetters } from 'vuex';
import MultiselectAfterList from '@/components/admin/MultiselectAfterList';
import NewRefundModal from '@/components/Modal/NewRefundModal';
import TransactionsTableComponent from "@/pages/Admin/Transactions/Components/TransactionsTableComponent.vue";

export default {
    name: 'TransactionEdit',
    components: {
      TransactionsTableComponent,
        MultiselectAfterList,
        Preloader: () => import('@/components/Preloader'),
        Multiselect: () => import('vue-multiselect'),
    },
    data() {
        return {
            form: {
                id: null,
                transaction_uuid: null,
                provider: null,
                updated_at: null,
                created_at: null,
                creator: null,
                amount: null,
                origin_amount: null,
                currency: null,
                status: null,
                student_id: null,
                product_name: null,
                product_id: null,
                name: null,
                phone: null,
                comment: null,
                edit: false,
                promo_code_id: null,
            },
            studentsMultiselectLoading: false,
            promoCodesMultiselectLoading: false,
            productsMultiselectLoading: false,
            loader: false,
            currency_list: null,
            statuses: null,
            status: null,
            students: null,
            student: null,
            studentHistory: {},
            promoCodes: null,
            promoCode: null,
            products: null,
            product: null,
            studentType: 'student_id', // student_id or info
            productType: 'subscription', // subscription || info || certificate || tournament
            refundFields: [
                {
                    key: 'id',
                    label: 'Id',
                    sortable: false,
                },
                {
                    key: 'creator',
                    label: 'Создано:',
                    sortable: false,
                },
                {
                    key: 'amount',
                    label: 'Цена:',
                    sortable: false,
                },
                {
                    key: 'comment',
                    label: 'Комментарий:',
                    sortable: false,
                },
                {
                    key: 'created_at',
                    label: 'Создан:',
                    sortable: false,
                },
            ],
            refundItems: null,
        };
    },
    computed: {
        ...mapGetters({
            getRole: 'user/getRole',
        }),
        // selectedStudentType() {
        //     return (type) => this.studentType === type;
        // },
        selectedProductType() {
            return (type) => this.productType === type;
        },
        isAdmin() {
            return this.getRole === 'admin';
        },
    },
    methods: {
        async submitForm() {
            try {
                this.formValidation();
                this.formPreparation();

                try {
                    await this.$axios.post(`crm/transactions/${this.$route.params.id}`, this.form, {
                        params: {
                            _method: 'patch',
                        },
                    });
                    this.$toasted.success('Запись обновлена', { position: 'bottom-right' });
                } catch (ex) {
                    console.log('cant update transaction: ' + ex);
                    this.$toasted.error(ex, { position: 'bottom-right' });
                }
            } catch (ex) {
                console.log('cant create transaction: ' + ex);
                this.$toasted.error(ex, { position: 'bottom-right' });
            }
        },
        formValidation() {
            if (!this.student && !(this.form.name && this.form.phone)) {
                throw new Error('Выберите студента');
            } else if (!this.product && !this.form.product_name) {
                throw new Error('Выберите продукт');
            }
        },
        formPreparation() {
            // if (this.selectedStudentType('student_id') && this.student) {
            //     this.$set(this.form, 'student_id', this.student.id);
            // }
            this.$set(this.form, 'student_id', this?.student?.id);

            if (this.promoCode) {
                this.$set(this.form, 'promo_code_id', this.promoCode.id);
            }

            if (this.selectedProductType('subscription') && this.product) {
                this.$set(this.form, 'product_id', this.product.id);
            }

            if (this.form.amount) {
                this.$set(this.form, 'amount', +this.form.amount);
            }

            if (this.form.origin_amount) {
                this.$set(this.form, 'origin_amount', +this.form.origin_amount);
            }
        },
        resetForm() {
            this.$router.push('/transactions');
        },
        async fetchCurrencies() {
            try {
                const { data } = await this.$axios.get('crm/transactions/get-currencies');
                this.currency_list = data;
            } catch (ex) {
                console.log('cant fetch currency: ' + ex);
            }
        },
        async fetchStatuses() {
            try {
                const { data } = await this.$axios.get('crm/transactions/get-statuses');
                this.statuses = data;
            } catch (ex) {
                console.log('cant fetch statuses: ' + ex);
            }
        },
        async fetchStudents(query = null) {
            try {
                const { data } = await this.$axios.get('crm/students/list', { params: query });

                if (!this.students) {
                    this.students = data;
                } else {
                    this.$set(this.students, 'data', [...this.students.data, ...data.data]);
                    this.$set(this.students, 'meta', data.meta);
                }
            } catch (ex) {
                console.log('cant fetch students: ' + ex);
            }
        },
        searchStudent: debounce(async function(query) {
            this.studentsMultiselectLoading = true;
            this.findStudent({ query })
                .then(response => {
                    this.students = response;
                    this.studentsMultiselectLoading = false;
                })
                .catch(ex => {
                    this.studentsMultiselectLoading = false;
                    console.log('cant find students: ' + ex);
                });
        }, 1000),
        async findStudent(query = null) {
            try {
                const { data } = await this.$axios.get('crm/students/list', { params: query });
                return data;
            } catch (ex) {
                console.log('cant find student: ' + ex);
            }
        },
        async fetchPromoCodes(query = null) {
            try {
                const { data } = await this.$axios.get('crm/promo-codes/list', { params: query });

                if (!this.promoCodes) {
                    this.promoCodes = data;
                } else {
                    this.$set(this.promoCodes, 'data', [...this.promoCodes.data, ...data.data]);
                    this.$set(this.promoCodes, 'meta', data.meta);
                }
            } catch (ex) {
                console.log('cant fetch promo codes: ' + ex);
            }
        },
        searchPromoCodes: debounce(async function(query) {
            this.promoCodesMultiselectLoading = true;
            this.findPromoCodes({ query })
                .then(response => {
                    this.promoCodes = response;
                    this.promoCodesMultiselectLoading = false;
                })
                .catch(ex => {
                    this.promoCodesMultiselectLoading = false;
                    console.log('cant find promo codes: ' + ex);
                });
        }, 1000),
        async findPromoCodes(query = null) {
            try {
                const { data } = await this.$axios.get('crm/promo-codes/list', { params: query });
                return data;
            } catch (ex) {
                console.log('cant find promo code: ' + ex);
            }
        },
        async fetchProducts(query = null) {
            try {
                const { data } = await this.$axios.get('crm/products/list', { params: query });

                if (!this.products) {
                    this.products = data;
                } else {
                    this.$set(this.products, 'data', [...this.products.data, ...data.data]);
                    this.$set(this.products, 'meta', data.meta);
                }
            } catch (ex) {
                console.log('cant fetch products: ' + ex);
            }
        },
        searchProduct: debounce(async function(query) {
            this.productsMultiselectLoading = true;
            this.findProduct({ query })
                .then(response => {
                    this.products = response;
                    this.productsMultiselectLoading = false;
                })
                .catch(ex => {
                    this.productsMultiselectLoading = false;
                    console.log('cant find products: ' + ex);
                });
        }, 1000),
        async findProduct(query = null) {
            try {
                const { data } = await this.$axios.get('crm/products/list', { params: query });
                return data;
            } catch (ex) {
                console.log('cant find products: ' + ex);
            }
        },
        async fetchTransaction() {
            try {
                const { data } = await this.$axios.get(`crm/transactions/${this.$route.params.id}/edit`);
                const transaction = data.data;

                for (const [k, v] of Object.entries(transaction)) {
                    this.form[k] = v;
                }

                if (transaction.student) {
                    this.form.student_id = transaction.student.id;
                    this.student = transaction.student;
                }

                if (transaction.promo_code) {
                    this.form.promo_code_id = transaction.promo_code.id;
                    this.promoCode = transaction.promo_code;
                }

                if (transaction.product) {
                    this.form.product_id = transaction.product.id;
                    this.product = transaction.product;
                }

                if (transaction.refunds) {
                    this.refundItems = transaction.refunds;
                }
            } catch (ex) {
                console.log('cant fetch transaction: ' + ex);
            }
        },
        promoCodeCustomLabel({ title, code }) {
            return `${title} - ${code}`;
        },
        openRefundModal(transaction) {
            this.$modal.show(
                NewRefundModal,
                {
                    transaction,
                    handler: this.refundItem,
                }, { name: 'RefundModal', height: 'auto' },
            );
        },
        async refundItem(amount, note) {
            try {
                await this.$axios.post(`crm/transactions/${this.$route.params.id}/refund`, {amount: parseInt(amount), note});
                this.$toasted.success('Товар возвращен ждите обновления статуса!', { position: 'bottom-right' });
                this.$modal.hideAll();
                await this.fetchTransaction();
            } catch (ex) {
                if (ex.response.data.error) {
                    this.$toasted.error(ex.response.data.error, { position: 'bottom-right' });
                } else {
                    this.$toasted.error('Ошибка!: ' + ex, { position: 'bottom-right' });
                }
            }
        },
        async handleStudentSelect(student) {
          console.log(student);
          this.student = student;
          await this.fetchStudentTransactions({student_id: this.student.id});
        },
        async fetchStudentTransactions(query = null) {
            try {
                const { data } = await this.$axios.get('crm/transactions', { params: query });
                this.studentHistory = data;
            } catch (ex) {
                if (ex.response.data.error) {
                    this.$toasted.error(ex.response.data.error, { position: 'bottom-right' });
                } else {
                    this.$toasted.error('Не удалось загрузить историю платежей студента', { position: 'bottom-right' });
                }
            }
        },
    },
    async created() {
        this.loader = true;
        await this.fetchTransaction();
        await this.fetchCurrencies();
        await this.fetchStatuses();
        await this.fetchStudents();
        await this.fetchPromoCodes();
        await this.fetchProducts();
        if (this.student) {
          await this.fetchStudentTransactions({student_id: this.student.id});
        }

        if (this.form.name || this.form.phone) {
            this.studentType = 'info';
        }

        if (this.form.product_name) {
            this.productType = 'info';
        }

        this.loader = false;
    },
};
</script>

<style lang='scss' scoped></style>