<template>
    <div class='refund-modal p-4'>
        <h3 class='mb-4'>Возврат средств</h3>

        <b-form @submit.prevent='handleFormSubmit'>
            <b-form-group label='ФИО покупателя:' label-for='customer'>
                <b-form-input id='customer' readonly :value='getCustomerName' />
            </b-form-group>

            <b-form-group label='Продукт' label-for='customer'>
                <b-form-input id='customer' readonly :value='getProductName' />
            </b-form-group>

            <b-form-group :label="`Сумма возврата: * (максимум ${getMaxPrice(transaction)})`" label-for='amount'>
                <input class="form-control" id='amount' type='number' :value="form.amount" @input="changeAmount" />
            </b-form-group>

            <b-form-group label='Причина возврата: *' label-for='comment'>
                <b-form-textarea v-model='form.comment' />
            </b-form-group>

            <b-form-group>
                <b-alert show variant="secondary" class='text-xl'>
                    <p><strong>Оплаченная сумма:</strong> {{ transaction.amount }}</p>
                    <p class='m-0'><strong>Сумма к возврату:</strong> {{ form.amount }}</p>
                </b-alert>
            </b-form-group>

            <div class='d-flex justify-content-center'>
                <b-button type='button' variant="danger" class='mr-2' @click='$modal.hideAll()'>Отмена</b-button>
                <b-button type='submit' variant="success">Подтвердить</b-button>
            </div>
        </b-form>
    </div>
</template>

<script>
export default {
    name: 'NewRefundModal',
    props: {
        transaction: {
            type: Object,
            required: true,
        },
        handler: {
            type: [Function],
            required: true,
        },
    },
    data() {
        return {
            form: {
                amount: 0,
                comment: '',
            }
        };
    },
    computed: {
        getCustomerName() {
            return this.transaction.student ? this.transaction.student.name : this.transaction.name;
        },
        getProductName() {
            return this.transaction.product ? this.transaction.product.title : this.transaction.product_name;
        },
    },
    methods: {
        async handleFormSubmit() {
            await this.handler(this.form.amount, this.form.comment);
        },
        changeAmount(e) {
            const max = this.getMaxPrice(this.transaction)

            if (e.target.value > max) {
                this.form.amount = max
                e.target.value = max
            } else {
                this.form.amount = e.target.value
            }

            e.preventDefault();
        },
        getMaxPrice(transaction) {
            let refundSum = 0;

            if (transaction.refunds.length > 0) {
                refundSum = transaction.refunds.reduce((accumulator, object) => {
                    return accumulator + parseFloat(object.amount);
                }, 0);
            }

            return transaction.amount - refundSum
        }
    },
};
</script>

<style lang='scss' scoped></style>