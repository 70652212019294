<template>
  <div>
    <div class='widget row'>
      <pagination-counter class="w-100 text-right my-3" :meta="pagination"/>
      <table class='table table-hover'>
        <thead class='table_head'>
        <tr>
          <th>#</th>
          <th>Имя</th>
          <th>Телефон</th>
          <th>Статус</th>
          <th>Сумма</th>
          <th>Промокод</th>
          <th>Наименование</th>
          <th>Касса</th>
          <th>Создан</th>
          <th>Оплачен</th>
          <th v-if="isShowActions">Действия</th>
        </tr>
        </thead>
        <tbody>
        <tr
            v-for='transaction in transactions'
            :key='transaction.id'
            :class="getConfirmClass(transaction.student_id, transaction.payment_from_profile)"
        >
          <td>{{ transaction.id }}</td>
          <td>{{ transaction.name }}</td>
          <td>{{ transaction.phone }}</td>
          <td>
            <b-badge
                :variant='badgeMap[transaction.status].css' pill
                :title='badgeMap[transaction.status].title'
            >
              {{ transaction.status }}
            </b-badge>
          </td>
          <td>{{ transaction.amount }} {{ transaction.currency }}</td>
          <td>{{ transaction.promo_code }}</td>
          <td>{{ transaction.product_name }}</td>
          <td>{{ transaction.provider }}</td>
          <td>{{ transaction.created_at }}</td>
          <td>{{ transaction.paid_at }}</td>
          <td class='actions_container' v-if="isEditable">
            <router-link
                class='leads_table_action_link btn mr-2 btn-primary'
                :to="{name: 'TransactionEdit', params:{ id: transaction.id }}"
            >
              <span class='glyphicon glyphicon-edit' />
            </router-link>
          </td>
        </tr>
        </tbody>
      </table>
      <p class="no-transaction-text" v-if="!transactions">Данные не были загружены</p>
      <p class="no-transaction-text" v-if="!Object.keys(this.transactions).length">Нет платежей</p>
    </div>

    <div v-if='pagination && pagination.last_page > 1' class='py-1 overflow-auto d-flex justify-content-between align-items-center'>
      <b-pagination
          class='paginator my-0'
          :total-rows='pagination.total'
          :per-page='pagination.per_page'
          @page-click='handlePageSelect'
          v-model="currentPage"
      />
      <pagination-counter class="mr-4" :meta="pagination"/>
    </div>
  </div>
</template>

<script>
import {BPagination} from "bootstrap-vue";
import PaginationCounter from "@/components/Pagination/PaginationCounter.vue";

export default {
  name: "TransactionsTableComponent",

  components: {PaginationCounter, BPagination},

  props: {
    tableData: {
      type: Object,
      required: true,
    },
    isEditable: {
      type: Boolean,
      default: false
    }
  },

  data() {
    return {
      // transactions : [],
      // pagination : null,
      currentPage: 1,
      badgeMap: {
        'в обработке (успех)': {
          title: 'заявка обработана деньги у клиента списаны, задержка подтверждения',
          css: 'success',
        },
        'ждет подтвержд': {
          title: 'ждет подтверждения магазина',
          css: 'yellow',
        },
        'в ожидании оплаты': {
          title: 'новая заявка, клиент еще не оплатил',
          css: 'gray',
        },
        'отказ по заявке': {
          title: 'отказано банком по какой либо причине или платеж не прошел',
          css: 'danger',
        },
        'не оплачен': {
          title: 'не оплачен',
          css: 'danger',
        },
        'отменен': {
          title: 'отменен',
          css: 'danger',
        },
        'успешная оплата': {
          title: 'успешная оплата',
          css: 'success',
        },
        'возврат': {
          title: 'возврат товара',
          css: 'yellow',
        },
      },
      stats: null,
      providers: null,
    }
  },

  computed: {
    isShowActions() {
      return (this.isEditable) ; /*Дополнять условие по необходимости*/
    },
    transactions() {
      return this.tableData.data ? this.tableData.data : []
    },
    pagination() {
      return this.tableData.meta ? this.tableData.meta : null
    },
  },

  methods: {
    async handlePageSelect(bvEvent, page) {
      if (page) {
        const query = Object.assign({}, this.$route.query, { page });
        await this.$router.push({ query: query });
      }

      return false;
    },

    getConfirmClass(studentId, fromProfile) {
      let classes = '';

      if (!studentId) {
        classes = 'not-confirm';
      }

      if (fromProfile) {
        classes = 'from-profile';
      }

      return classes;
    },
  }
}
</script>

<style scoped lang="scss">

.not-confirm {
  background-color: rgba(255, 0, 0, 0.15);
}

.from-profile {
  background-color: rgba(62, 134, 202, 0.15);
}

.no-transaction-text {
  padding: 25px;
  min-width: 100%;
  display: block;
  text-align: center;
  font-size: 24px;
}

</style>