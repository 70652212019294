import { render, staticRenderFns } from "./TransactionEdit.vue?vue&type=template&id=54b45e14&scoped=true"
import script from "./TransactionEdit.vue?vue&type=script&lang=js"
export * from "./TransactionEdit.vue?vue&type=script&lang=js"


/* normalize component */
import normalizer from "!../../../../node_modules/@vue/cli-service/node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "54b45e14",
  null
  
)

export default component.exports